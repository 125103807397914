import { Modal, Button, Collapse, Divider } from 'antd';
import { QuestionCircleOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';
import React from 'react';
import utils from '../utils';

const { Panel } = Collapse;

class Help extends React.Component {
	state = { visible: false };

	showModal = () => {
	  this.setState({
		visible: true,
	  });
	};
  
	handleOk = e => {
	  this.setState({
		visible: false,
	  });
	};
  
	handleCancel = e => {
	  this.setState({
		visible: false,
	  });
	};


	render() {
		const { page } = this.props;
		if (!page) return null;

		let items = null;
		let collapseBlock = [];

		switch (page){
			case "general":
			items = [{
				key: 1,
				question: "How often does reporting update (Visits, targets, and other metrics)?",
				answer: "Most stats in the platform update every 15-20 minutes. Often times clicks on the ad stats pages update faster than this but it is dependent on a few variables. Please be aware that pulling Digital PDF reports generates a saved cache for the date range you request for the report, meaning that re-opening the same date range will result in the same results. If you are pulling a report for the current date, your numbers will not change often. Store visits metrics are updated every morning between 2-4am when the previous days impressions are fully logged and can be calculated efficiently. It’s important to note that running large reports can cause impression/ click stats to be slightly different from each other especially with live/ actively spending campaigns. This is because impressions and clicks can increase DURING the time it takes to calculate the reporting pages. A discrepancy up to 3-5% is common for very large campaigns that are live.",
			},
			{
				key: 2,
				question: "How long after creating a campaign should I expect impressions (assuming there is available inventory)?",
				answer: "New ads must pass audit before they can be served to live users. If your ads have previously passed audit and you start a campaign, you should expect new impressions reported within 20 minutes. During weekdays/ business hours most campaigns that need ad audits will go live within 3-4 hours. Holidays and weekends can make ad audit take upwards of 72 hours in extreme cases. Ad audits are independent of the platform and we cannot control how fast they are completed. Please make sure you follow guidelines on creative, a good reference, and the strictest documentation on this can be found on Google: https://support.google.com/ authorizedbuyers/answer/1325008?hl=en",
			},
			{
				key: 3,
				question: "What are the top ad sizes/ creative spec?",
				answer: "300x250, 320x50, 300x50, 728x90 represents 95% of mobile inventory calls. You may also utilize 300x600, 160x600 and other popular sizes but please be aware that there is little likelihood that they will be displayed to users often. You may utilize 3rd party ad servers to display rich media, video, etc. and the platform supports ad macros to power them to the best of our ability. For the platform hosted ads we require JPG, PNG, or GIF file types with a max 100kb size to conform with fast load times required by mobile devices. GIF images may only loop for a maximum of 15 seconds (some mobile ad slots allow 30 seconds but it is best to not limit your options).",
			},
			{
				key: 4,
				question: "Why google analytics doesn't line up with clicks/ sessions?",
				answer: "It’s frustrating to see that often times Google Analytics does not match up with the platform stats. Why is this? It turns out that even Google Ads don’t line up with Google analytics even though they are two of Googles most notable products used together by millions of advertisers. The short answer is that most advertising platforms log clicks the moment a user presses an ad unit. The time to log that click is extremely fast because the ad unit is already loaded on a users device and the connection to the server delivering that click is pretty much already defined/ loaded/ ready for new requests. However, Google analytics needs to log a session to log a users activity and present you with it’s key metrics. In order to do this it has to wait for the user to load the landing page, have javascript enabled, cookies enabled, and a it can finally send messages to itself about that users activity. Time on site, session count, clicks that generated sessions are all tied to this process. Please see Googles articles about how this works here: https://support.google.com/analytics/answer/4588315?hl=en - https://support.google.com/analytics/answer/4589209?hl=en",
			}];
			break;
			case "missionControl":
			items = [{
				key: 1,
				question: "How often does reporting update (Visits, targets, and other metrics)?",
				answer: "Most stats in the platform update every 15-20 minutes. Often times clicks on the ad stats pages update faster than this but it is dependent on a few variables. Please be aware that pulling Digital PDF reports generates a saved cache for the date range you request for the report, meaning that re-opening the same date range will result in the same results. If you are pulling a report for the current date, your numbers will not change often. Store visits metrics are updated every morning between 2-4am when the previous days impressions are fully logged and can be calculated efficiently. It’s important to note that running large reports can cause impression/ click stats to be slightly different from each other especially with live/ actively spending campaigns. This is because impressions and clicks can increase DURING the time it takes to calculate the reporting pages. A discrepancy up to 3-5% is common for very large campaigns that are live.",
			},
			{
				key: 2,
				question: "How long after creating a campaign should I expect impressions (assuming there is available inventory)?",
				answer: "New ads must pass audit before they can be served to live users. If your ads have previously passed audit and you start a campaign, you should expect new impressions reported within 20 minutes. During weekdays/ business hours most campaigns that need ad audits will go live within 3-4 hours. Holidays and weekends can make ad audit take upwards of 72 hours in extreme cases. Ad audits are independent of the platform and we cannot control how fast they are completed. Please make sure you follow guidelines on creative, a good reference, and the strictest documentation on this can be found on Google: https://support.google.com/ authorizedbuyers/answer/1325008?hl=en",
			},];
			break;
			case "creative":
					items = [{
						key: 1,
						question: "What are the top ad sizes/ creative spec?",
						answer: "300x250, 320x50, 300x50, 728x90 represents 95% of mobile inventory calls. You may also utilize 300x600, 160x600 and other popular sizes but please be aware that there is little likelihood that they will be displayed to users often. You may utilize 3rd party ad servers to display rich media, video, etc. and the platform supports ad macros to power them to the best of our ability. For the platform hosted ads we require JPG, PNG, or GIF file types with a max 100kb size to conform with fast load times required by mobile devices. GIF images may only loop for a maximum of 15 seconds (some mobile ad slots allow 30 seconds but it is best to not limit your options).",
					},
					{
						key: 2,
						question: "How long after creating a campaign should I expect impressions (assuming there is available inventory)?",
						answer: "New ads must pass audit before they can be served to live users. If your ads have previously passed audit and you start a campaign, you should expect new impressions reported within 20 minutes. During weekdays/ business hours most campaigns that need ad audits will go live within 3-4 hours. Holidays and weekends can make ad audit take upwards of 72 hours in extreme cases. Ad audits are independent of the platform and we cannot control how fast they are completed. Please make sure you follow guidelines on creative, a good reference, and the strictest documentation on this can be found on Google: https://support.google.com/ authorizedbuyers/answer/1325008?hl=en",
					},{
						key: 3,
						question: "How do I add UTM codes to my landing pages?",
						answer: "Please use: https://ga-dev-tools.appspot.com/campaign-url-builder/",
					},];
			break;
			case "segments":
					items = [{
						key: 1,
						question: "How many geofences can I make?",
						answer: "Searching the map will only return 15 locations at a time. However, you can fence as many locations as you wish. The more populated seg will likely see more impressions and it is sometimes preferable to make seperate segments and campaigns for lower volume areas.",
					},
					{
						key: 2,
						question: "How tight can a radius be?",
						answer: "Geofences can detect devices down to 10ft with optimal conditions and GPS tracking. However, 100ft is the lowest we recommend for most situations.",
					},{
						key: 3,
						question: "Can my geofence be anywhere in the world?",
						answer: "You can make geofence segments anywhere in the world, however, we currently only support impressions in the United States, Canada, nearby island neighbors, and Mexico.",
					},{
						key: 4,
						question: "How do IDFA downloads work and how do I upload them into FB?",
						answer: "The platform stores IDFA’s for users seen within particular segments or geofences. You can then download or buy these lists and utilize them in 3rd party media buying strategize. One of those 3rd parties is often Facebook. Facebook audience manager allows you to upload lists of IDFA’s in their audience builder. Audience builder takes up to 72 hours to match the IDFA’s we give you to users / devices of users with Facebook and Instagram profiles. You can then target those profiles with ads, videos, and or sponsored posts.",
					},];
			break;
			case "campaigns":
					items = [{
						key: 1,
						question: "How should I go about targeting campaigns with geofencing based on my budget tiers?",
						answer: "Do not have 1,000 targets for a $1,000 budget! You will spread your money out so thin that results are very hard to come by. Instead try to make one target for every $300-$400 you want to spend per month. The smaller the target radius, the more targets you can have with a smaller budget. If you have 6 mile radius large fences in highly populated areas then you will need to increase your budget or keep just one or two targets for that campaign.",
					},
					{
						key: 2,
						question: "How long after creating a campaign should I expect impressions (assuming there is available inventory)?",
						answer: "New ads must pass audit before they can be served to live users. If your ads have previously passed audit and you start a campaign, you should expect new impressions reported within 20 minutes. During weekdays/ business hours most campaigns that need ad audits will go live within 3-4 hours. Holidays and weekends can make ad audit take upwards of 72 hours in extreme cases. Ad audits are independent of our platform and we cannot control how fast they are completed. Please make sure you follow guidelines on creative, a good reference, and the strictest documentation on this can be found on Google: https://support.google.com/ authorizedbuyers/answer/1325008?hl=en",
					},{
						key: 3,
						question: "How do I alter my campaigns to get more clicks?",
						answer: "1.) Watch your creative to see what messages perform better overtime. 2.) Set a frequency cap so that users are limited to 7-42 ads per day, week, month. For high ticket items (Rolex, Home sales, Auto, Insurance, etc) increase to higher numbers and for low priced “easily converting” items you should use a smaller number around 7. 3.) Download excel file reports with domains and sort your domain list by CTR. Go back to your campaign and create a blacklist to remove low click/ low CTR domains from your campaigns going forward as they run.",
					},{
						key: 4,
						question: "Mobile web vs mobile in-app inventory?",
						answer: "In the platform, under the campaign creation/ edit page you can turn on the inventory type app settings and select between desktop, mobile in-app, mobile web. For geofencing you should ONLY select mobile types. It is possible for desktop ads to leak into geofence campaigns because there are often still desktops within physical world locations. Sometimes devices will default themselves to desktop traffic when the exact specifications of a device are not available, skewing metrics even when the concern is likely unfounded. - Mobile web ads are slightly different than mobile in-app ads. Mobile web means the user on a device is browsing via safari, chrome, explorer, firefox, etc. - Mobile web ads are generally the same size as desktop ads but some mobile friendly websites deliver only mobile specific sizes when the device is detected. - Mobile web ads track and deliver location information the same way desktop ads do and they are not as accurate due to GPS constraints and HTML 5 permissions. Mobile in-app means your ads will be delivered within mobile native apps only. Generally, GPS location is available and the accuracy/ persistence of location information is of a much higher quality. These ad sizes are generally only mobile sizes but 300x250 is still very prevalent. The most common size is 300x50.",
					},{
						key: 5,
						question: "What are best practices to increase campaign fill?",
						answer: "1.) Do not suppress scale with frequency cap. 2.) Increase the size of your targeted audiences or geofences/ geofence lookback days. 3.) Increase the number of ad sizes attached to your campaign. 4.) Increase your min/ max bid setting. 5.) Audit your campaign settings to make sure you are not suppressing your campaign using whitelist/ blacklist settings or inventory settings.",
					},];
			break;
			case "reporting":
					items = [{
						key: 1,
						question: "How often does reporting update (Visits, targets, and other metrics)?",
						answer: "Most stats in the platform update every 15-20 minutes. Often times clicks on the ad stats pages update faster than this but it is dependent on a few variables. Please be aware that pulling Digital PDF reports generates a saved cache for the date range you request for the report, meaning that re-opening the same date range will result in the same results. If you are pulling a report for the current date, your numbers will not change often. Store visits metrics are updated every morning between 2-4am when the previous days impressions are fully logged and can be calculated efficiently. It’s important to note that running large reports can cause impression/ click stats to be slightly different from each other especially with live/ actively spending campaigns. This is because impressions and clicks can increase DURING the time it takes to calculate the reporting pages. A discrepancy up to 3-5% is common for very large campaigns that are live.",
					},
					{
						key: 2,
						question: "How long after creating a campaign should I expect impressions (assuming there is available inventory)?",
						answer: "New ads must pass audit before they can be served to live users. If your ads have previously passed audit and you start a campaign, you should expect new impressions reported within 20 minutes. During weekdays/ business hours most campaigns that need ad audits will go live within 3-4 hours. Holidays and weekends can make ad audit take upwards of 72 hours in extreme cases. Ad audits are independent of the platform and we cannot control how fast they are completed. Please make sure you follow guidelines on creative, a good reference, and the strictest documentation on this can be found on Google: https://support.google.com/ authorizedbuyers/answer/1325008?hl=en",
					},{
						key: 3,
						question: "Why does pulling a report with domains and visits take so long?",
						answer: "The larger a campaign is, and there more targets it is actively serving impressions on, the longer your report will take to generate domain/ store visit stats. The calculations are so large that 4 years ago it took over 4 days to process some campaigns. For 1k+ target limitless domain campaigns you can expect results within 30 minutes.",
					},{
						key: 4,
						question: "Why do I have little to no visits in my first few days when starting campaigns?",
						answer: "It takes time and frequency of ads to drive physical world influence. You should expect visits after 3-7 days depending on your target vs store conversion zone locations and the distance between them.",
					},];
			break;
			case "pixels":
					items = [{
						key: 1,
						question: "How often do pixel metrics update?",
						answer: "Within 20 minutes of pixels firing.",
					},{
						key: 2,
						question: "How do I place pixels?",
						answer: "Please see our youtube tutorial: https://www.youtube.com/watch?v=5pq9VDSX-K4",
					},];
			break;
			default:
			
		}

		for (let item in items){
			collapseBlock.push(<Panel header={items[item].question} key={items[item].key}><p>{items[item].answer}</p></Panel>);
		}

		return (
			<>
				<Button type="dashed" onClick={this.showModal}> {this.props.page === "general" ? "FAQ" : "Help"} <QuestionCircleOutlined /></Button>
				<Modal
					title="Common questions related to this page:"
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
				>
					<Collapse>
						{collapseBlock}
					</Collapse>
					<Divider dashed />
					<p style={{ textAlign: 'center' }}>
						<b>Need additional support?</b>
						<br />
						<PhoneOutlined /> <a href={`tel:${utils.brand.phone}`}>{utils.brand.phone}</a>
						<br />
						<MailOutlined /> <a href={`mailto:${utils.brand.email}`}>{utils.brand.email}</a>
					</p>
				</Modal>
			</>
		);
	}
}

export default Help;
