import { Layout } from 'antd';
import React, { Component } from 'react';
import utils from '../utils';
import FooterNav from './footer';
import LeftNav from './leftnav';
import Nav from './nav';

const { Content } = Layout;

export default function withAuth(AuthComponent, nolayout = false) {
	const { auth } = utils;
	return class AuthWrapped extends Component {
		constructor(props) {
			super(props);

			this.state = {
				user: null,
			};
			utils.history = props.history;
		}

		async componentDidMount() {
			const isLoggedIn = await auth.loggedIn();

			if (!isLoggedIn) {
				/* eslint no-restricted-globals: 0 */
				utils.goto(utils.home());
				return;
			}

			try {
				const profile = await auth.getProfile();

				this.setState({
					user: profile,
				});
			} catch (err) {
				console.warn(err);
				auth.logout();
				utils.goto('/login');
			}
		}

		render() {
			const { user } = this.state,
				{ history, match } = this.props;
			if (!user) return null;

			let { uid } = this.props.match.params,
				rid = auth.getUID();
			if (!uid) uid = rid;
			utils.profile = user;
			utils.uid = uid;
			utils.realID = rid;
			utils.isSameUser = rid === uid;
			utils.history = history;
			utils.params = !!match && !!match.params ? match.params : {};
			if (nolayout) {
				return (
					<React.Suspense fallback={<div>Loading...</div>}>
						<AuthComponent asHost={utils.asHost} {...this.props} />
					</React.Suspense>
				);
			}

			return (
				<Layout>
					<Nav ref={utils.navRef} />
					<Layout>
						<LeftNav />
						<Content>
							{utils.adminMessage ? (
								<>
									<h2 style={{ color: 'orange', textAlign: 'center', padding: 20 }}>
										<strong>Admin Message: </strong>
										{utils.adminMessage}
									</h2>
								</>
							) : null}
							<React.Suspense fallback={<div>Loading...</div>}>
								<AuthComponent asHost={utils.asHost} {...this.props} />
							</React.Suspense>
						</Content>
					</Layout>
					<FooterNav />
				</Layout>
			);
		}
	};
}
