import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Divider, Input, message, Row } from 'antd';
import React from 'react';
import utils from '../utils';

const FormItem = Form.Item;

class NormalForgotPasswordForm extends React.Component {
	state = {token: null};

	componentDidMount(){
		/* eslint no-restricted-globals: 0 */
		const m = location.pathname.match(/resetPassword\/(.+)$/)
		if (m) this.setState({token: m[1]});
	}

	handleReq = (e) => {
		e.preventDefault();
		utils.setLoading(this, 'formLoading', true);

		this.props.form.validateFields(async (err, values) => {
			if (!err) {
				//console.log('Received values of form: ', values);
				try {
					await utils.auth.reqResetPass(values.email);
					message.success("Please check your email!");
				} catch(err) {
					utils.showErr(err);
				}
			}
			utils.setLoading(this, 'formLoading', false);
		});
	}

	handleReset = (e) => {
		e.preventDefault();
		utils.setLoading(this, 'formLoading', true);

		this.props.form.validateFields(async (err, values) => {
			if (err) {
				utils.setLoading(this, 'formLoading', false);
				return;
			}
			//console.log('Received values of form: ', values);

			try {
				const { email } = await utils.auth.resetPass(values.token, values.password);
				await utils.auth.login(email, values.password)

				// check user type for correct redirect
				const data = await utils.auth.getUser()
				message.success("Welcome back!");
				utils.goto('/settings/' + data.id);
			} catch(err) {
				console.error(err);
				try {
					utils.showErr(err);
				} catch(e) {}
			}

			utils.setLoading(this, 'formLoading', false);
		});
	}

	reqResetPass() {
		const { getFieldDecorator } = this.props.form,
			{ formLoading } = this.state;

		return (
            <Form onSubmit={this.handleReq} className="login-form">
				<FormItem>
					{getFieldDecorator('email', {
						rules: [{ required: true, message: 'Please input your email!' }],
					})(
						<Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
					)}
				</FormItem>
				<FormItem>
					<Button type="primary" htmlType="submit" className="login-form-button" loading={formLoading}>
						Request Password Reset
					</Button>
					<Divider />
					<span>Other options: </span>
					<a href="/login">Login</a> - <a href="/signup">Signup</a>
				</FormItem>
			</Form>
        );
	}

	resetPass(token) {
		const { getFieldDecorator } = this.props.form,
			{ formLoading } = this.state;

		return (
            <Form onSubmit={this.handleReset} className="login-form">
				<FormItem>
					{getFieldDecorator('password', {
						rules: [{ required: true, message: 'Please input your Password!' }],
					})(
						<Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
					)}
					{getFieldDecorator('token', {
						rules: [{ required: false }],
						initialValue: token,
					})(
						<Input name="token" type="hidden" />
					)}
				</FormItem>
				<FormItem>
					<Button type="primary" htmlType="submit" className="login-form-button" loading={formLoading}>
						Change Password
					</Button>
					<Divider />
					<span>Other options: </span>
					<a href="/login">Login</a> - <a href="/signup">Signup</a>
				</FormItem>
			</Form>
        );
	}

	render() {
		const { token } = this.state;
		if(!token) return this.reqResetPass();

		return this.resetPass(token);
	}
}

const WrappedNormalForgotPasswordForm = Form.create()(NormalForgotPasswordForm);


class ForgotPasswordPage extends React.Component {

	render() {
		let { brand } = utils;
		if (!brand) return null;
		return(
			<Row>
				<div className="logoDark" style={{ background: `url(${utils.brand.logo}) no-repeat 50% 50%` }} />
				<Divider />
				<Col><WrappedNormalForgotPasswordForm /></Col>
			</Row>
		);
	}
}

export default ForgotPasswordPage;
