import React, { lazy } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import '../components/footer';
import '../components/leftnav';
// for effect only
import '../components/nav';
import withAuth from '../components/withAuth';
//import Home from './Home';
import history from '../history';
import utils from '../utils';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Signup from './Signup';



/////

//const Login = lazy(() => import('./Login'));
const Logout = lazy(() => import('./Logout'));
//const Signup = lazy(() => import('./Signup'));
const Signup2 = lazy(() => import('./Signup2'));
const Signup3 = lazy(() => import('./Signup3'));
const Admin = lazy(() => import('./Admin'));
const CampaignGV = lazy(() => import('./CampaignGV'));
const Search = lazy(() => import('./Search'));
const GlobalAudienceViz = lazy(() => import('./GlobalAudienceViz'));
const Agency = lazy(() => import('./Agency'));
const Lookup = lazy(() => import('./Lookup'));
const Settings = lazy(() => import('./Settings'));
const Campaign = lazy(() => import('./Campaign'));
const Creative = lazy(() => import('./Creatives'));
const RetargetingSegment = lazy(() => import('./RetargetingSegment'));
const GeofenceSegment = lazy(() => import('./GeofenceSegment'));
const PrettyReport = lazy(() => import('./PrettyReport'));

const ManageCreative = lazy(() => import('./ManageCreative'));
const ManageSegments = lazy(() => import('./ManageSegments'));
const ManageCampaigns = lazy(() => import('./ManageCampaigns'));
const ManageReporting = lazy(() => import('./ManageReporting'));
const ManageLists = lazy(() => import('./ManageLists'));
const DMP = lazy(() => import('./DMP'));


const ManagePixels = lazy(() => import('./ManagePixels'));
const ThankYou = lazy(() => import('./ThankYou'));
const Welcome = lazy(() => import('./Welcome'));
const MissionControl = lazy(() => import('./MissionControl'));
const GeofenceReport = lazy(() => import('./GeofenceReport'));
const SubmitCampaign = lazy(() => import('./SubmitCampaign'));
const AddBilling = lazy(() => import('./AddBilling'));
const UserEdit = lazy(() => import('./UserEdit'));

function redirWithID(props) {
	const { match } = props,
		uid = utils.auth.getUID();
	if (uid && match && match.path) return <Redirect to={match.path + '/' + uid} />;
	return <Redirect to={utils.getPath('/')} />;
}

function redirBeta(props) {
	return <Redirect to={utils.getPath(props.location.pathname)} />;
}

function goHome() {
	return <Redirect to={utils.home()} />;
}

export default () => (
	<Router history={history}>
		<Switch>
			<Route path={utils.getPath("/")} exact render={goHome} />
			<Route path={utils.getPath("/login")} exact component={Login} />
			<Route path={utils.getPath("/forgotPassword")} exact component={ForgotPassword} />
			<Route path={utils.getPath("/resetPassword/:token")} exact component={ForgotPassword} />
			<Route path={utils.getPath("/logout")} exact component={Logout} />
			<Route path={utils.getPath("/signup")} exact component={Signup} />
			<Route path={utils.getPath("/signup/2/:uid")} exact component={withAuth(Signup2, true)} />
			<Route path={utils.getPath("/signup/3/:uid")} exact component={withAuth(Signup3, true)} />
			<Route path={utils.getPath("/admin")} exact component={withAuth(Admin)} />
			<Route path={utils.getPath("/campaignGV")} exact component={withAuth(CampaignGV)} />
			<Route path={utils.getPath("/search")} exact component={withAuth(Search)} />
			<Route path={utils.getPath("/globalAudience")} exact component={GlobalAudienceViz} />
			<Route path={utils.getPath("/lookup")} exact component={withAuth(Lookup)} />
			<Route path={utils.getPath("/agency/:uid")} exact component={withAuth(Agency)} />
			<Route path={utils.getPath("/dashboard/:uid")} exact component={withAuth(MissionControl)} />
			<Route path={utils.getPath("/settings/:uid")} exact component={withAuth(Settings)} />

			<Route path={utils.getPath("/create/creative/:uid")} exact component={withAuth(Creative)} />
			<Route path={utils.getPath("/edit/creative/:uid/:creativeID")} exact component={withAuth(Creative)} />

			<Route path={utils.getPath("/create/segment/retargeting/:uid")} exact component={withAuth(RetargetingSegment)} />
			<Route path={utils.getPath("/edit/segment/retargeting/:uid/:segmentID")} exact component={withAuth(RetargetingSegment)} />

			<Route path={utils.getPath("/create/segment/geofence/:uid")} exact component={withAuth(GeofenceSegment)} />
			<Route path={utils.getPath("/edit/segment/geofence/:uid/:segmentID")} exact component={withAuth(GeofenceSegment)} />

			<Route path={utils.getPath("/create/segment/store/:uid")} exact component={withAuth(GeofenceSegment)} />
			<Route path={utils.getPath("/edit/segment/store/:uid/:segmentID")} exact component={withAuth(GeofenceSegment)} />

			<Route path={utils.getPath("/create/campaign/:uid")} exact component={withAuth(Campaign)} />
			<Route path={utils.getPath("/edit/campaign/:uid/:campaignID")} exact component={withAuth(Campaign)} />
			<Route path={utils.getPath("/edit/campaign/draft/:uid/:campaignID")} exact component={withAuth(Campaign)} />

			{/* remove these later after verifying they all work with single page setup */}
			<Route path={utils.getPath("/manage/creative/:uid")} exact component={withAuth(ManageCreative)} />
			<Route path={utils.getPath("/manage/segments/:uid")} exact component={withAuth(ManageSegments)} />
			<Route path={utils.getPath("/manage/stores/:uid")} exact component={withAuth(ManageSegments)} />
			<Route path={utils.getPath("/manage/campaigns/:uid")} exact component={withAuth(ManageCampaigns)} />
			<Route path={utils.getPath("/manage/reporting/:uid")} exact component={withAuth(ManageReporting)} />
			<Route path={utils.getPath("/manage/lists/:uid")} exact component={withAuth(ManageLists)} />
			<Route path={utils.getPath("/manage/agency/reporting/:uid")} exact component={withAuth(ManageReporting)} />
			<Route path={utils.getPath("/data/studio/:uid")} exact component={withAuth(DMP)} />
			<Route path={utils.getPath("/manage/pixels/:uid")} exact component={withAuth(ManagePixels)} />
			<Route path={utils.getPath("/welcome/:uid")} exact component={withAuth(Welcome)} />
			<Route path={utils.getPath("/missionControl/:uid")} exact component={withAuth(MissionControl)} />
			{/* <Route path={utils.getPath("/geofence/report/:uid")} exact component={withAuth(GeofenceReport)} /> */}
			<Route path={utils.getPath("/geofence/report/:uid")} exact component={GeofenceReport} />

			<Route path={utils.getPath("/submitCampaign/:uid")} exact component={SubmitCampaign} />
			<Route path={utils.getPath("/campaign/prettyReport/:uid/:cid")} exact component={withAuth(PrettyReport, true)} />
			<Route path={utils.getPath("/campaign/report/:uid/:cid")} exact component={withAuth(PrettyReport, true)} />
			<Route path={utils.getPath("/campaign/report/:uid/:cid/:from/:to")} exact component={withAuth(PrettyReport, true)} />
			<Route path={utils.getPath("/advertiser/report/:uid")} exact component={withAuth(PrettyReport, true)} />
			<Route path={utils.getPath("/advertiser/report/:uid/:from/:to")} exact component={withAuth(PrettyReport, true)} />
			<Route path={utils.getPath("/advertiserReport/:rid")} exact component={PrettyReport} />

			<Route path={utils.getPath("/billing/add/:uid")} exact component={withAuth(AddBilling)} />
			<Route path={utils.getPath("/thankyou/:uid")} exact component={withAuth(ThankYou)} />
			<Route path={utils.getPath("/user/edit/:uid")} exact component={withAuth(UserEdit)} />

			<Route path={utils.getPath("/agency")} exact render={redirWithID} />
			<Route path={utils.getPath("/user/edit")} exact render={redirWithID} />
			<Route path={utils.getPath("/settings")} exact render={redirWithID} />
			<Route path={utils.getPath("/missionControl")} exact render={redirWithID} />
			<Route path={utils.getPath("/geofence/report")} exact render={redirWithID} />
			<Route path={utils.getPath("/create/automation")} exact render={redirWithID} />
			<Route path={utils.getPath("/create/creative")} exact render={redirWithID} />
			<Route path={utils.getPath("/create/segment/retargeting")} exact render={redirWithID} />
			<Route path={utils.getPath("/create/segment/geofence")} exact render={redirWithID} />
			<Route path={utils.getPath("/create/campaign")} exact render={redirWithID}/>
			<Route path={utils.getPath("/manage/creative")} exact render={redirWithID} />
			<Route path={utils.getPath("/manage/segments")} exact render={redirWithID} />
			<Route path={utils.getPath("/manage/campaigns")} exact render={redirWithID} />
			<Route path={utils.getPath("/manage/stores")} exact render={redirWithID} />
			<Route path={utils.getPath("/manage/pixels")} exact render={redirWithID} />
			<Route path={utils.getPath("/manage/lists")} exact render={redirWithID} />
			<Route path={utils.getPath("/manage/reporting")} exact render={redirWithID} />
			<Route path={utils.getPath("/thankyou")} exact render={redirWithID} />
			<Route path={utils.getPath("/welcome")} exact render={redirWithID} />
			<Route render={redirBeta} />
		</Switch>
	</Router>
)
