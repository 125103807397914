import 'antd/dist/antd.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import utils from './utils';

function createLink(cssPath) {
	const ele = document.createElement('link');
	ele.rel = 'stylesheet';
	ele.type = 'text/css';
	ele.href = cssPath;
	ele.media = 'all';
	document.body.appendChild(ele);
}

function addCss(css) {
	const ele = document.createElement('style');
	ele.innerText = css;
	document.body.appendChild(ele);
}

(async function() {
	await utils.auth.loggedIn();
	const data = utils.brand;
	document.title = data.name;

	if (data.css) createLink(data.css);
	if (data.logoLight) addCss(`.logoLight { background: url(${data.logoLight}); }`);
	if (data.logo) addCss(`.logoDark { background: url(${data.logo}) no-repeat 50% 50%; }`);

	// @ts-ignore
	if (data.favicon) document.querySelector('link#favicon').href = data.favicon;
	ReactDOM.render(<App />, document.getElementById('root'));
})()
