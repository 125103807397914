import decode from 'jwt-decode';
import { Perms } from '../perms';
import utils from '../utils';

function autoPilot(email, data = {}) {
	const ap = window['Autopilot'];
	if (!ap) return;

	for (const k of Object.keys(data.custom)) {
		const v = data.custom[k];
		if (typeof v === 'boolean') {
			data.custom[k] = v ? 'yes' : 'no';
		}

		if (k.indexOf('string--') !== 0) {
			data.custom['string--' + k] = data.custom[k];
			delete data.custom[k];
		}
	}

	// console.log('autoPilot', email, data);
	try {
		ap.run('associate', {
			// _simpleAssociate: Object.keys(data).length > 0,
			Email: email,
			...data,
		});
	} catch (err) {
		console.error('autopilot error:', err);
	}
}

function sleep(ms = 0) {
	return new Promise((r) => setTimeout(r, ms));
}

export default class AuthService {
	// Initializing important variables
	constructor(domain) {
		/* eslint no-restricted-globals: 0 */
		const isLocal = location.hostname === 'localhost';
		this.domain = domain || isLocal ? '/api/v1' : `https://${location.host}/api/v1`;

		this.currentUser = null;
		this.initialUser = null;
		this.isLocal = isLocal;
	}

	signup = async (email, goal, pass) => {
		const { id } = await this.fetch(`signup`, 'POST', {
			email,
			pass,
			passConfirm: pass,
		});

		this.setUID(id);
		autoPilot(email, { custom: { 'string--WLbrand': location.host, 'string--userType': 'advertiser' } });
		return id;
	};

	signupFromAgencyOrAdmin = async (name, email, phone, pass, instaUser, instaPass, type, agencyID, address) => {
		//console.log(name, email, phone, pass, instaUser, instaPass, type, agencyID);
		const data = await this.fetch(`signup`, 'POST', {
			user: {
				type,
				agencyID,

				profile: {
					name: name,
					email: email,
					phone: phone,
					password: pass,
				},

				instagram: {
					username: instaUser,
					password: instaPass,
				},

				address,
			},
		});

		autoPilot(email, { custom: { 'string--WLbrand': location.host, 'string--userType': type } });
		return data;
	};

	login = async (email, pass, pid = '') => {
		// Get a token from api server using the fetch api
		await this.fetch(`signIn`, 'POST', {
			email,
			pass,
			pid,
		});

		const { id } = await this.fetch(`userID`);
		// if it errors it won't get this far
		this.setUID(id);

		autoPilot(email, { custom: { 'string--WLbrand': location.host } });

		return id;
	};

	reassocAll = async () => {
		const all = await this.get('debug/autoPilot');
		for (const u of all) {
			try {
				autoPilot(u.email, { custom: u.custom });
				// console.log(u.email, { custom: u.custom });
			} catch (err) {
				console.error('u', u, err);
			}
			sleep(100);
		}
	};

	reqResetPass = async (email) => this.fetch(`reqResetPassword`, 'POST', { email });
	resetPass = async (token, password) => this.fetch(`resetPassword`, 'POST', { token, password });

	stats = async (uid, numDays = '30', withVisits = false) => {
		const d = await this.fetch(`reporting/${uid}/${numDays}?noAds=true${withVisits ? '&withVisits=true' : ''}`),
			u = this.currentUser;

		if (u && u.profile)
			autoPilot(u.profile.email, {
				custom: {
					hasActivity: !!d && d.length > 0,
				},
			});

		return d;
	};

	advStats = async (uid, start, end) => {
		try {
			return await this.fetch(`advertiserStats/${uid}/${start}/${end}`);
		} catch (err) {
			return {};
		}
	};

	campaignsSpent = async (uid, start = null, end = null) => {
		return this.fetch(`campaignsSpent/${uid}` + (end ? `/${start}-${end}` : `/${start || '30'}`));
	}

	targetStats = async (uid) => {
		// Get a token from api server using the fetch api
		return this.fetch(`targets/stats/${uid}`);
	};

	blockStats = async (uid) => {
		// Get a token from api server using the fetch api
		return this.fetch(`block/stats/${uid}?users=10`);
	};

	setBlockBots = async (uid, status) => {
		return this.fetch(`blockBots/${uid}/${status ? 'true' : 'false'}`, 'PUT');
	};

	setPodOptOut = async (uid, status) => {
		return this.fetch(`podOptOut/${uid}/${status ? 'true' : 'false'}`, 'PUT');
	};

	getInstaLocations = async (lat, lng, uid) => {
		return this.fetch(`location/${uid}/${lat}/${lng}`);
	};

	getInstaPlaces = async (search, uid) => {
		return this.fetch(`coords/${uid}/${search}`);
	};

	getUser = async (uid = null, noNav = false) => {
		const ruid = uid || this.getUID(),
			u = await this.fetch('users/' + ruid);

		try {
			u.profile = await this.fetch('profiles/' + ruid + (u.type === 'advertiser' ? `?agencyID=${u.agencyID}` : ''));
		} catch (err) {
			u.profile = {};
		}

		const isSub = (this.initialUser || u).profile.isSub;
		u.perms = new Perms(isSub ? u.perms : null) ;
		if (noNav) return u;
		if (!this.initialUser) this.initialUser = u;
		this.currentUser = u;

		if (utils.navRef.current) utils.navRef.current.setUser(u);

		if (u && u.profile)
			autoPilot(u.profile.email, {
				custom: {
					hasInstagramConnected: !!u.instagram && !!u.instagram.username,
				},
			});

		return u;
	};

	putUserExtraData = async (uid, key, value) => {
		if (value === false) value = undefined;
		return this.put(`userExtraData/${uid}`, { key, value });
	};

	getAds = async (uid) => {
		return this.fetch(`adsList/${uid}`);
	}

	getSegments = async (uid) => {
		const segs = await this.fetch(`segmentsList/${uid}`) || [];
		return segs.map((v) => { return {id: v.id, ...v.data } });
	}

	getIFASegments = async (uid) => {
		return await this.fetch(`ifaSegmentsList/${uid}`) || [];
	}

	getProxSegments = async (uid) => {
		const segs = await this.fetch(`segments/proximity/byOwner/${uid}`);
		return Object.keys(segs).map((k) => {
			const v = segs[k];
			v.id = k;
			return v;
		});
	}

	getStoreSegments = async (uid) => {
		const segs = await this.fetch(`segments/inStoreVisits/byOwner/${uid}`);
		return Object.keys(segs).map((k) => {
			const v = segs[k];
			v.id = k;
			v.uniqueUsers = v.visits;
			return v;
		});
	}

	getAllSegments = async (uid) => {
		let [ retargeting, proximity, ifas, isvs ] = await Promise.all([
			this.getSegments(uid),
			this.getProxSegments(uid),
			this.getIFASegments(uid),
			this.getStoreSegments(uid),
		]);

		retargeting = retargeting.map((v) => { return {...v, key: 're-' + v.id, type: 'Retargeting' }});
		proximity = proximity.map((v) => { return {...v, key: 'px-' + v.id, type: 'Geofence' }});
		ifas = ifas.map((v) => { return {...v, key: 'if-' + v.id, type: 'IDFA' }});
		isvs = isvs.map((v) => { return {...v, key: 'is-' + v.id, type: 'Store' }});

		const all = [ ...retargeting, ...proximity, ...ifas, ...isvs ].sort(utils.sorter('name'))
		return all;
	}

	getAdGroups = async (uid) => {
		const adGroups = await this.fetch(`adGroupsList/${uid}`) || [];

		return adGroups.sort(utils.sorter((v) => v.data.name));
	}

	getCampaigns = async(uid, from = '30', to = null) => {
		return this.fetch(`campaignsList/${uid}${from && to ? `/${from}-${to}` : from ? `?days=${from}`: ''}`);
	}

	getDrafts = async(uid) => {
		return this.fetch(`campaignDrafts/${uid}`);
	}

	linkUser = async (uid, agencyID) => {
		return this.fetch(`link/${uid}/${agencyID}`, 'PUT');
	};

	setOpt = async (uid, status) => {
		return this.fetch(`opt/${uid}/${status}`, 'PUT');
	};

	manuallyChangePlan = async (uid, planID) => {
		return this.fetch(`manualPlan/${uid}/${planID}`, 'PUT');
	};

	planOverride = async (uid, planID, value) => {
		return this.fetch(`planOverride/${uid}/${planID}/${value}`, 'PUT');
	};

	editUser = async (uid, type, company, email, domain, fixedFee, fee, currentPass = null, pass = null) => {
		if (pass) {
			await this.fetch(`changePassword/${uid}`, 'POST', { currentPass, pass});
			if (!utils.isSuper || uid === "1") await utils.auth.login(email, pass);
		}
		const userReq = { ...utils.currentUser, fixedFee };

		userReq[type === 'advertiser' ? 'advertiserFee' : 'agencyFee'] = fee;

		return await Promise.all([
			this.fetch(`profiles/${uid}`, 'PUT', {company, email, domain}),
			this.fetch(`users/${uid}`, 'PUT', { ...userReq, profile: null, perms: null }),
		])
	};

	getBilling = async (uid) => {
		try {
			return await this.fetch(`billing/${uid}`);
		} catch (err) {
			return null;
		}
	};

	addBilling = async (firstName, lastName, zip, cardNumber, cvv, expMonth, expYear, uid) => {
		const data = this.fetch(`payments/cc/${uid}`, 'PUT', {
			firstName,
			lastName,
			zip,
			cardNumber,
			cvv,
			expMonth,
			expYear,
		});

		return data;
	};

	deleteBilling = async (uid) => {
		return this.fetch(`billing/${uid}`, 'DELETE');
	};

	deleteStarter = async (uid) => {
		return this.fetch(`plan/${uid}`, 'DELETE');
	};

	deleteBillingPaypal = async (uid) => {
		return this.fetch(`paypal/${uid}`, 'DELETE');
	};

	deleteBillingAmazon = async (uid) => {
		return this.fetch(`amazon/${uid}`, 'DELETE');
	};

	getAutomation = async (uid, autoID) => {
		return this.fetch(`automations/${uid}/${autoID}`);
	};

	// prettier-ignore
	createAutomation = async (name, targets, likeValue, commentValue, messageValue, followValue, comments, messages, startTime, endTime, uid, type, autoID, blockBots, nurtureLikes, nurtureDM, blockNegative, includeTags) => {
		const url = autoID ? `automations/${uid}/${autoID}` : `automations/${uid}`;
		let automationName = name;
		let hashTargets = (targets ? targets : null);
		let usernames = (targets ? targets : null);
		let locTargets = (targets ? targets : null);
		let searchTargets = targets;
		let competitors = (targets ? targets : null);
		let likeVal = (likeValue ? true : false);
		let commentVal = (commentValue ? true : false);
		let messageVal = (messageValue ? true : false);
		let followVal = (followValue ? true : false);
		let eventStartTime = (startTime ? startTime : '');
		let eventEndTime = (endTime ? endTime : '');

		if(type === 'hashtag' || type === 'event'){
			hashTargets = targets;
			locTargets = null;
			searchTargets = null;
			competitors = null;
			usernames = null;
		} else if (type === 'custom') {
			hashTargets = null;
			searchTargets = null;
			locTargets = null;
			competitors = null;
			usernames = targets;
		} else if (type === 'location') {
			hashTargets = null;
			searchTargets = null;
			locTargets = targets;
			competitors = null;
			usernames = null;
		} else if (type === 'competitor') {
			hashTargets = null;
			searchTargets = null;
			locTargets = null;
			competitors = targets;
			usernames = null;
		}

		return this.fetch(url, autoID ? 'PUT' : 'POST', {
			userID: uid,
			name: automationName,
			isActive: true,
			automationType: type,
			blockBots,
			nurtureLikes,
			nurtureDM,
			blockNegative,
			platform: {
				hashtags: hashTargets && {
					tag: hashTargets,
					start: eventStartTime ? eventStartTime : '',
					end: eventEndTime ? eventEndTime : '',
				},
				locations: searchTargets,
				coords: [],
				externalIDs: locTargets,
				competitors: competitors,
				comments: comments,
				messages: messages,
				shouldLike: likeVal,
				shouldComment: commentVal,
				shouldMessage: messageVal,
				shouldFollow: followVal,
				includeTags,
				usernames,
			},
		});
	}

	deleteDraft = async (cmpID) => {
		// Get a token from api server using the fetch api
		return this.fetch(`campaignDraft/${cmpID}`, 'DELETE');
	};

	deleteCmp = async (cmpID) => {
		// Get a token from api server using the fetch api
		return this.fetch(`campaigns/byCID/${cmpID}`, 'DELETE');
	};

	getUsersByAgency = async (uid, listArchived = false) => {
		return this.fetch(`advertiserList/${uid}${listArchived ? '?all=true' : ''}`);
	};

	editAutomation = async (uid) => {
		// Get a token from api server using the fetch api
		return this.fetch(`stats/${uid}`, 'PUT' /*some payload*/);
	};

	manageAutomations = async (uid, nostats) => {
		// Get a token from api server using the fetch api
		const a = await this.fetch(`automations/${uid}${nostats ? '?block=true' : ''}`),
			u = this.currentUser;

		if (u && u.profile)
			autoPilot(u.profile.email, {
				custom: {
					hasAutomations: !!a && a.length > 0 ? 'yes' : 'no',
				},
			});

		return a;
	};

	manageBlocks = async (uid) => {
		// Get a token from api server using the fetch api
		return this.fetch(`block/dump/${uid}/-1/-1`);
	};

	getAgencies = async () => {
		// Get a token from api server using the fetch api
		return this.fetch(`agenciesList`);
	};

	getAdminStats = async () => {
		// Get a token from api server using the fetch api
		return this.fetch(`insights`);
	};

	getExchangeData = async (numDays = 7) => {
		let data = await this.get(`cachedExchangeData/${numDays || 7}`),
			ret = {};
		const dummy = {
			adx: {
				'2019-10-12': { requests: 0, bids: 0, impressions: 278623, cost: 551.2944530999997 },
				'2019-10-13': { requests: 0, bids: 0, impressions: 270726, cost: 546.9479835000003 },
				'2019-10-14': { requests: 0, bids: 0, impressions: 272023, cost: 712.0997922999999 },
				'2019-10-15': { requests: 0, bids: 0, impressions: 274984, cost: 783.8501043000001 },
				'2019-10-16': { requests: 0, bids: 0, impressions: 277475, cost: 795.7539902000001 },
				'2019-10-17': { requests: 0, bids: 0, impressions: 280560, cost: 818.0622797000003 },
				'2019-10-18': { requests: 0, bids: 0, impressions: 285248, cost: 814.3713139 },
				'2019-10-19': { requests: 0, bids: 0, impressions: 271747, cost: 792.2223443000005 },
			},
			beeswax: {},
			casale: {
				'2019-10-12': { requests: 0, bids: 0, impressions: 259805, cost: 704.7548627 },
				'2019-10-13': { requests: 0, bids: 0, impressions: 258521, cost: 704.3348413000001 },
				'2019-10-14': { requests: 0, bids: 0, impressions: 205486, cost: 579.5301534 },
				'2019-10-15': { requests: 0, bids: 0, impressions: 114673, cost: 350.5028813000001 },
				'2019-10-16': { requests: 0, bids: 0, impressions: 122482, cost: 361.3988343 },
				'2019-10-17': { requests: 0, bids: 0, impressions: 266093, cost: 662.2241404000001 },
				'2019-10-18': { requests: 0, bids: 0, impressions: 269540, cost: 669.5107382 },
				'2019-10-19': { requests: 0, bids: 0, impressions: 328732, cost: 771.941171 },
			},
			rubicon: {},
		};

		if(this.isLocal) data = dummy;

		for(const key of Object.keys(data)) {
			const d = {
				total: {impressions: 0, cost: 0},
				yesterday: {impressions: 0, cost: 0},
			};

			for(const date of Object.keys(data[key])) {
				const v = data[key][date]
				d.total.impressions += v.impressions
				d.yesterday.impressions = v.impressions
				d.total.cost += v.cost
				d.yesterday.cost = v.cost
			}

			ret[key] = d;
		}

		return ret;
	};

	health = async () => {
		try {
			return await this.fetch('health');
		} catch(err) {
			console.log(err);
			return [];
		}
	}

	getAllTimeStats = async () => {
		// Get a token from api server using the fetch api
		return this.fetch(`alltime`);
	};

	setIO = async (uid, status) => {
		return this.fetch(`ioState/${uid}/${status ? 1 : -1}`, 'PUT');
	};

	brands = async () => {
		return this.fetch(`brands`, 'GET', null, { noxhost: true });
	};

	deleteUser = async (uid) => {
		return this.fetch(`advertisers/${uid}`, 'DELETE');
	};

	deleteAgency = async (uid) => {
		return this.fetch(`agencies/${uid}`, 'DELETE');
	};

	deleteAd = async (aid, iid) => {
		if (iid) await this.fetch(`images/${iid}`, 'DELETE');
		if (aid) await this.fetch(`ads/${aid}?decGroup=true`, 'DELETE');
	}

	loggedIn = async () => {
		try {
			if (!utils.brand) {
				const [ brand, adminMessage ] = await Promise.all([
					await this.fetch('brand'),
					await this.fetch('adminMessage'),
				])
				utils.brand = brand;
				utils.adminMessage = !!adminMessage && adminMessage.message;
			}
			await this.fetch(`userID`);
			return true;
		} catch (e) {
			return false;
		}
	};

	isTokenExpired(token) {
		try {
			const decoded = decode(token);
			if (decoded.exp < Date.now() / 1000) {
				// Checking if token is expired. N
				return true;
			} else return false;
		} catch (err) {
			return false;
		}
	}

	setToken(idToken) {
		// Saves user token to localStorage
		localStorage.setItem('swayToken', idToken);
	}

	setUID(uid) {
		localStorage.setItem('swayUID', uid);
	}

	getToken() {
		// Retrieves the user token from localStorage
		return localStorage.getItem('swayToken');
	}

	getUID() {
		// Retrieves the user token from localStorage
		return localStorage.getItem('swayUID');
	}

	logout() {
		// Clear user token and profile data from localStorage
		localStorage.removeItem('swayToken');
		localStorage.removeItem('swayUID');
		utils.user = null;
		utils.asHost = '';
		utils.allBrands = [];
		utils.uid = '';
		utils.realID = '';
	}

	getProfile = async (uid) => {
		return this.getUser(uid);
	};

	getProxSegment = async (pxID, args = null) => {
		const seg = await this.get(`segments/proximity/byKey/${pxID}?${args}`);
		seg.id = pxID;
		return seg;
	}

	get = async (ep) => this.fetch(`${ep}`);
	put = async (ep, payload = null) => this.fetch(`${ep}`, 'PUT', payload);
	post = async (ep, payload = null) => this.fetch(`${ep}`, 'POST', payload);
	delete = async (ep) => this.fetch(`${ep}`, 'DELETE');

	fetch = async (url, method = 'GET', payload = null, opts = {}) => {
		// if (method !== 'GET' && method !== 'POST') throw new Error('nooooooo'); // REMOVE
		if (!url.startsWith(this.domain)) url = this.domain + '/' + url;
		// performs api calls sending the required authentication headers
		const req = {
			body: null,
			method: method || 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			cache: 'no-cache',
			...opts,
		};

		if (utils.asHost && (!opts || !opts.noxhost)) {
			req.headers['X-Host'] = utils.asHost;
		}

		if (payload instanceof FormData || payload instanceof Blob) {
			req.body = payload;
			delete req.headers['Content-Type'];
		} else if (typeof payload === 'string') {
			req.body = payload;
		} else if (typeof payload === 'function') {
			req.body = payload(req);
		} else if (payload != null) {
			req.body = JSON.stringify(payload);
		}

		// @ts-ignore
		const resp = await fetch(url, req),
			ct = resp.headers.get('Content-Type'),
			isJSON = !!ct && ct.startsWith('application/json');

		if (!isJSON) {
			const txt = (await resp.text()) || resp.statusText;
			if (resp.status > 299) throw new Error(txt);
			return txt;
		}

		let r = null;
		try {
			r = await resp.json();
		} catch (e) {}

		if (r && Array.isArray(r.errors)) {
			// errors are an array of objects or strings, so return that and join it.
			throw r.errors
				.map((err) => (err.message ? err.message : err))
				.filter((m) => !!m)
				.join('\n');
		}

		if (r && (r.code > 300 || !!r.error)) {
			throw r.message || r.error;
		}

		return r && 'data' in r ? r.data : r;
	};
}
