import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import utils from '../utils';
import Help from '../components/help';

const { Header } = Layout;

class Nav extends React.Component {
	state = {
		user: null,
	};

	setUser = (user) => this.setState({ user });

	setBrand = async (b) => {
		// TODO: this needs to be fixed if we ever reuse this logic
		/* eslint no-restricted-globals: 0 */
		const loc = location.pathname;
		utils.asHost = b.host;
		utils.brand= b.dash || {};
		await utils.utils.goto('/');
		await utils.utils.goto(loc);
	};

	getBrands = () => {
		const { allBrands, asHost } = utils;
		if (!Array.isArray(allBrands) || allBrands.length < 2) return null;
		/* eslint no-script-url: 0 */
		const menu = (
				<Menu>
					{allBrands.map((b, i) => (
						<Menu.Item key={i}>
							<a href={'#' + b.host} onClick={() => this.setBrand(b)}>
								{b.host}
							</a>
						</Menu.Item>
					))}
				</Menu>
			),
			host = asHost || allBrands.find((b) => b.name === 'Vizns').host;

		return (
			<Dropdown overlay={menu}>
				<Button>{host}</Button>
			</Dropdown>
		);
	};

	render() {
		const { uid, brand } = utils;

		return <>
			<Header className="header" style={{ padding: '0px' }}>
				<div className="logo" style={{ marginLeft: '25px', background: `url(${brand.logo}) no-repeat 50% 50%` }} />
				<Menu theme="light" mode="horizontal" style={{ lineHeight: '62px' }}>
					<Menu.Item key="1">
						<NavLink style={{ fontWeight: 'bold' }} to={`/settings/${uid}`} activeClassName="selected">
							<SettingOutlined /> Settings
						</NavLink>
					</Menu.Item>

					<Menu.Item key="3">
						<NavLink style={{ fontWeight: 'bold' }} to="/logout">
							<LogoutOutlined /> Logout
						</NavLink>
					</Menu.Item>

					<Menu.Item key="44">
						<Help page="general"/>
					</Menu.Item>
					
				</Menu>
			</Header>
		</>;
	}
}
/* outside link menu item
<Menu.Item key="2">
	<a href="http://youtube.com" target="_blank" rel="noopener noreferrer">
		<Icon type="youtube" /> Tutorial Guides
	</a>
</Menu.Item>
*/

export default Nav;
