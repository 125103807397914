import { Layout } from 'antd';
import React from 'react';
import utils from '../utils';

const { Footer } = Layout;

class FooterNav extends React.Component {
	render() {
		const { brand: dd } = utils;
		return(
			<Footer style={{ textAlign: 'center', backgroundColor: '#fff', borderTop: '#00a8d1 solid 1px' }}>
				© 2020 Created by {dd.name.toUpperCase()} | <a href={dd.termsURL} target="_blank" rel="noopener noreferrer">Terms</a> - <a href={dd.privacyURL} target="_blank" rel="noopener noreferrer">Privacy - Cookies - EULA</a>
			</Footer>
		);
	}
}

export default FooterNav;
