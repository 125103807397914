import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Divider, Input, List, message, Row } from 'antd';
import React from 'react';
import utils from '../utils';

const FormItem = Form.Item;

class NormalLoginForm extends React.Component {
	path = '';
	state = {};

	componentDidMount() {
		if (utils.auth.loggedIn()) {
			utils.auth.logout();
		}

		this.path = utils.queryParam('p');
	}

	handleSubmit = (e) => {
		e.preventDefault();
		utils.setLoading(this, 'formLoading', false);

		this.props.form.validateFields(async (err, values) => {
			if (!err) {
				//console.log('Received values of form: ', values);

				try {
					await utils.auth.login(values.email, values.password);
					sessionStorage.removeItem('refCode'); // unconditionally remove it
					sessionStorage.removeItem('fppc'); // unconditionally remove it

					// check user type for correct redirect
					const u = await utils.auth.getUser();
					if (u.type === 'sub' && !!u.subUsers) {
						const users = u.subUsers
							.map((v) => v.split(':', 2))
							.map((v) => ({ id: v[0], name: v[1].trim() }))
							.filter((v) => {
								return v.name && v.name !== 'N/A';
							})
							.sort((a, b) => a.name.localeCompare(b.name));
						this.setState({ users, ...values });
						return;
					}
					message.success('Welcome back');
					await utils.goto(this.path || utils.home());
					return;
				} catch (err) {
					utils.showErr(err);
				}
			}
			utils.setLoading(this, 'formLoading', false);
		});
	};

	handleSubUser = async (id) => {
		const { email, password } = this.state;
		try {
			await utils.auth.login(email, password, id);
			await utils.auth.getUser();
			message.success('Welcome back');
			await utils.goto(this.path || utils.home());
		} catch (err) {
			utils.showErr(err);
		}
	};

	render() {
		const { getFieldDecorator } = this.props.form,
			{ formLoading, users } = this.state;

		return (
			<Form onSubmit={this.handleSubmit} className="login-form">
				<FormItem>
					{getFieldDecorator('email', {
						rules: [{ required: true, message: 'Please input your email!' }],
					})(
						<Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} autoComplete="username" placeholder="Email" />
					)}
				</FormItem>
				<FormItem>
					{getFieldDecorator('password', {
						rules: [{ required: true, message: 'Please input your Password!' }],
					})(
						<Input
							prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
							type="password"
							autoComplete="password"
							placeholder="Password"
						/>
					)}
				</FormItem>
				<p>
					<small>
						By submitting this form and logging into your account, you accept our{' '}
						<a href={utils.brand.termsURL}>Terms & Conditions</a> and our <a href={utils.brand.privacyURL}>Privacy Policy</a>
					</small>
				</p>
				{!!users && !!users.length ? (
					<>
						<h4>Select Advertiser:</h4>
						<List
							dataSource={users}
							renderItem={(user) => (
								<List.Item>
									<List.Item.Meta
										avatar={<Avatar shape="square" src={`/avatar/${user.id}`} />}
										title={`${user.name} (${user.id})`}
									/>
									<Button type="primary" onClick={() => this.handleSubUser(user.id)}>
										Open Account
									</Button>
								</List.Item>
							)}
						/>
					</>
				) : (
					<FormItem>
						<Button type="primary" htmlType="submit" className="login-form-button" loading={formLoading}>
							Log in
						</Button>
						{/*<Divider />
					<span>Other options: </span>
					<a href="/forgotPassword">Forgot Password</a>*/}
					</FormItem>
				)}
			</Form>
		);
	}
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm);

class LoginPage extends React.Component {
	async componentDidMount() {
		await utils.auth.loggedIn();
		this.forceUpdate();
	}

	render() {
		let { brand } = utils;
		if (!brand) return null;
		return (
			<Row>
				<div className="logoDark" />
				<Divider />
				{utils.adminMessage ? (
					<>
						<h2 style={{ color: 'orange' }}>
							<strong>Admin Message: </strong>
							{utils.adminMessage}
						</h2>
						<Divider />
					</>
				) : null}
				<Col>
					<WrappedNormalLoginForm />
				</Col>
			</Row>
		);
	}
}

export default LoginPage;
