import { Col, Layout, Row } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import FooterNav from '../components/footer';
import LoginPage from '../pages/login';
import utils from '../utils';

export default ({ history }) => {
	utils.history = history;
	return (
		<div>
			<Row style={{ minHeight: '700px', backgroundImage: `url("//meteora.co/whitelabel/bg.jpg")` }}>
				<Col md={6} xs={2}></Col>
				<Col md={12}>
					<Layout style={{ padding: '0 24px 24px', margin: '20px 0' }}>
						<Layout
							style={{
								background: 'rgb(255, 255, 255)',
								padding: 24,
								margin: 0,
								minHeight: 280,
								boxShadow: 'rgb(0, 0, 0, 0.2) 0px 0px 20px',
							}}>
							<LoginPage />
						</Layout>
					</Layout>
				</Col>
				<Col md={6} xs={2}></Col>
			</Row>
			<FooterNav />
		</div>
	);
};
