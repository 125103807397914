import { Col, Layout, Row } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import FooterNav from '../components/footer';
import ForgotPasswordPage from '../pages/forgotPassword';


export default () => (
	<div>
		<Row style={{ minHeight: '700px', backgroundImage: `url("//meteora.co/whitelabel/bg.jpg")` }}>
			<Col md={6} xs={2}></Col>
				<Col md={12}>
					<Layout style={{ padding: '0 24px 24px', margin: '20px 0' }}>
						<Layout style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280, boxShadow: '0px 0px 53px #000' }}>
							<ForgotPasswordPage />
						</Layout>
					</Layout>
				</Col>
			<Col md={6} xs={2}></Col>
		</Row>
		<FooterNav />
	</div>
);
