import {
	AreaChartOutlined,
	BorderHorizontalOutlined,
	DashboardOutlined,
	DotChartOutlined,
	GlobalOutlined,
	MailOutlined,
	NotificationOutlined,
	PhoneOutlined,
	PictureOutlined,
	SafetyCertificateOutlined,
	SearchOutlined,
	ShopTwoTone,
	StarOutlined,
	UserOutlined
} from '@ant-design/icons';
import { Avatar, Divider, Layout, Menu } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import utils from '../utils';


//const { SubMenu } = Menu;
const { Sider } = Layout;

class LeftNav extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			user: null,
			uid: utils.uid,
			collapsed: false,
		};
	}

	onCollapse = (collapsed) => {
		this.setState({ ...this.state, collapsed });
	};

	async componentDidMount() {
		const { uid } = this.state,
			user = utils.currentUser;

		if (user.id === uid) return this.setState({ user });

		const data = await utils.auth.getUser(uid);
		this.setState({ ...this.state, user: data });
	}

	render() {
		const { user } = this.state,
			{ uid, realID, isSameUser } = utils,
			userAvatar = `/avatar/${uid}`,
			adminAgencyLinks = [],
			criticalAlerts = [],
			isHidden = (p) => !user.perms.canView(p);

		if (!user) return null;


		if (!isSameUser) {
			adminAgencyLinks.push(
				<Menu.Item key="7">
					<NavLink to={realID === '1' ? '/admin/' : `/agency/${realID}`} style={{ color: '#fd7e14', fontWeight: 'bold' }}>
						<ShopTwoTone twoToneColor="#fd7e14" />
						<span>{realID === '1' ? 'Admin' : 'Agency'} Home</span>
					</NavLink>
				</Menu.Item>
			);
		}

		let menu = [];
		if (user.type === 'admin') {
			/* eslint no-restricted-globals: 0 */
			menu.push(
				<Menu
					theme="dark"
					mode="inline"
					//defaultSelectedKeys={['1']}
					defaultOpenKeys={['sub1']}
					style={{ borderRight: 0 }}
					key={menu.length}>
					{adminAgencyLinks}
					<Menu.Item key="1" style={{ color: '#fff', fontWeight: 'bold' }}>
						<NavLink to={`/admin`}>
							<DashboardOutlined /> <span>Admin Dashboard</span>
						</NavLink>
					</Menu.Item>
					<Menu.Item key="3" style={{ color: '#fff', fontWeight: 'bold' }}>
						<NavLink to={`/campaignGV`}>
							<StarOutlined /> <span>Campaigns overview</span>
						</NavLink>
					</Menu.Item>
					<Menu.Item key="2" style={{ color: '#fff', fontWeight: 'bold' }}>
						<NavLink to={`/search`}>
							<SearchOutlined /> <span>Search platform</span>
						</NavLink>
					</Menu.Item>
					<Menu.Item key="333333" style={{ color: '#fff', fontWeight: 'bold' }}>
						<NavLink to={`/globalAudience`}>
							<GlobalOutlined /> <span>Global Audience</span>
						</NavLink>
					</Menu.Item>
				</Menu>
			);
		} else if (user.type === 'agency') {
			menu.push(
				<React.Fragment key="main">
					<div style={{ textAlign: 'center' }}>
						<NavLink to={`/settings/${uid}`}>
							<Avatar shape="square" style={{ marginTop: '20px', marginBottom: '15px' }} size={90} icon={<UserOutlined />} src={userAvatar} />
							<h4 style={{ marginBottom: '10px', color: '#fff' }}>
								<b>{user.profile.company}</b>
							</h4>
						</NavLink>
						<Divider style={{ marginBottom: '5px' }} />
					</div>
					<Menu
						theme="dark"
						mode="inline"
						//defaultSelectedKeys={['1']}
						defaultOpenKeys={['sub1']}
						style={{ borderRight: 0 }}
						key={menu.length}>
						{adminAgencyLinks}
						<Menu.Item key="5" style={{ color: '#fff', fontWeight: 'bold' }}>
							<NavLink to={`/agency/${uid}`}>
								<DashboardOutlined /> <span>Agency Dashboard</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item key="26" style={{ color: '#fff', fontWeight: 'bold' }}>
							<NavLink to={`/manage/agency/reporting/${uid}`}>
								<DotChartOutlined /> <span>Pull Reports</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item key="27" style={{ color: '#fff', fontWeight: 'bold' }}>
							<NavLink to={`/manage/lists/${uid}`}>
								<GlobalOutlined /> <span>Domain Lists</span>
							</NavLink>
						</Menu.Item>
					</Menu>
				</React.Fragment>
			);
		} else {
			menu.push(
				<React.Fragment key="main">
					<div style={{ textAlign: 'center' }}>
						<NavLink to={`/settings/${uid}`}>
							<Avatar shape="square" style={{ marginTop: '20px', marginBottom: '15px' }} size={90} icon={<UserOutlined />} src={userAvatar} />
							<h4 style={{ marginBottom: '0px', color: '#fff' }}>
								<b>{user.profile.company}</b>
							</h4>
						</NavLink>
						{utils.isSuper ? (
							<small style={{ color: '#f0f0f0' }}>
								<NavLink to={`/agency/${user.agencyID}`}>{user.profile.agencyName}</NavLink>
							</small>
						) : null}
						<Divider style={{ marginBottom: '5px' }} />
					</div>
					<Menu
						theme="dark"
						mode="inline"
						//defaultSelectedKeys={['1']}
						defaultOpenKeys={['sub1']}
						style={{ borderRight: 0 }}
						key={menu.length}>
						{adminAgencyLinks}

						<Menu.Item key="22" style={{ color: '#fff', fontWeight: 'bold' }}>
							<NavLink to={`/missionControl/${uid}`}>
								<AreaChartOutlined />
								<span>Quickstats</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item key="23" style={{ color: '#fff', fontWeight: 'bold' }} hidden={isHidden('ads')}>
							<NavLink to={`/manage/creative/${uid}`}>
								<PictureOutlined /> <span>Creative</span>{' '}
							</NavLink>
						</Menu.Item>
						<Menu.Item key="24" style={{ color: '#fff', fontWeight: 'bold' }} hidden={isHidden('segments')}>
							<NavLink to={`/manage/segments/${uid}`}>
								<BorderHorizontalOutlined /> <span>Segments</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item key="25" style={{ color: '#fff', fontWeight: 'bold' }} hidden={isHidden('campaigns')}>
							<NavLink to={`/manage/campaigns/${uid}`}>
								<NotificationOutlined /> <span>Campaigns</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item key="26" style={{ color: '#fff', fontWeight: 'bold' }}>
							<NavLink to={`/manage/reporting/${uid}`}>
								<DotChartOutlined /> <span>Pull Reports</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item key="27" style={{ color: '#fff', fontWeight: 'bold' }}>
							<NavLink to={`/manage/pixels/${uid}`}>
								<SafetyCertificateOutlined /> <span>Pixels</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item key="28" style={{ color: '#fff', fontWeight: 'bold' }}>
							<NavLink to={`/manage/lists/${uid}`}>
								<GlobalOutlined /> <span>Domain Lists</span>
							</NavLink>
						</Menu.Item>
					</Menu>
				</React.Fragment>
			);
		}

		return (
            <Sider
				style={{ background: '#001529', minHeight: '800px', marginTop: '2px' }}
				collapsed={this.state.collapsed}
				onCollapse={this.onCollapse}
				collapsible
				width="220"
				breakpoint="lg"
				collapsedWidth="0">
				{criticalAlerts}
				{menu}
				<Divider dashed />
				<p style={{ textAlign: 'center', color: '#fff' }}>
					<b>Need support?</b>
					<br />
					<PhoneOutlined /> <a href={`tel:${utils.brand.phone}`}>{utils.brand.phone}</a>
					<br />
					<MailOutlined /> <a href={`mailto:${utils.brand.email}`}>{utils.brand.email}</a>
				</p>
			</Sider>
        );
	}
}

export default LeftNav;
