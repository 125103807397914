import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Col, Divider, Input, Row, Steps } from 'antd';
import React from 'react';
import kwList from '../kwList';
import utils from '../utils';


const FormItem = Form.Item;

function getRefCode() {
	const v = sessionStorage.getItem('refCode');
	if (v) return v;

	/* eslint no-restricted-globals: 0 */
	const m = location.search.match(/(?:[?&]|^)signupCode=([a-zA-Z0-9-_]+)(?:[&]|$)/);
	return m && m.length === 2 ? m[1] : '';
}

function getForcedPlanCode() {
	const v = sessionStorage.getItem('fppc');
	if (v) return v;

	/* eslint no-restricted-globals: 0 */
	const m = location.search.match(/(?:[?&]|^)fppc=([0-9])(?:[&]|$)/);
	return m && m.length === 2 ? m[1] : '';
}

class NormalSignupFormStep1 extends React.Component {
	state = {
		loading: false,
		hasStarter: utils.plans()[0].id === -1,
	};

	componentDidMount() {
		const refCode = getRefCode(),
			fppc = getForcedPlanCode();

		if (refCode) sessionStorage.setItem('refCode', refCode);
		if (fppc) sessionStorage.setItem('fppc', fppc);
	}

	enterLoading = () => {
		this.setState({ loading: true });
	};

	handleSubmitProfile = async (e) => {
		e.preventDefault();
		utils.setLoading(this, 'formLoading', true);

		this.props.form.validateFields(async (err, values) => {
			if (err) {
				this.setState({ loading: false, formLoading: false });
				return;
			}

			try {
				const uid = await utils.auth.signup(values.email, values.goal, values.pass, getRefCode()),
					{ hasStarter } = this.state;

				utils.goto('/signup/2/' + uid);
				if (!hasStarter) return;

				const goal = kwList[values.goal];
				if (!goal) return;
				utils.auth.createAutomation(values.goal, goal, null, null, null, true, null, null, null, null, uid, 'hashtag');
			} catch (err) {
				utils.showErr(err);
				this.setState({ loading: false, formLoading: false });
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form,
			{ formLoading } = this.state,
			{ brand: dd } = utils;

		return (
            <div>
				<Form onSubmit={this.handleSubmitProfile} className="login-form">
					<b style={{ color: '#f5222d' }}>Create an account:</b>
					<br />
					<small>This info will be used to login to view stats, manage your campaigns, and settings.</small>
					<br />
					<br />
					<FormItem>
						{getFieldDecorator('email', {
							rules: [
								{
									required: true,
									message: 'Please input your email!',
								},
							],
						})(
							<Input
								prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
								autoComplete="username"
								placeholder="Your email address"
								name="email"
							/>
						)}
					</FormItem>
					<FormItem>
						{getFieldDecorator('pass', { rules: [{ required: true, message: 'Please create a password!' }] })(
							<Input
								prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
								type="password"
								name="pass"
								autoComplete="new-password"
								placeholder="Password you want to use"
							/>
						)}
					</FormItem>


					<FormItem>
						{getFieldDecorator('terms', {
							rules: [
								{
									required: true,
									message: 'You must agree to our terms of use and privacy policies!',
								},
							],
							valuePropName: 'checked',
						})(
							<Checkbox>
								I agree to the <a href={dd.termsURL}>terms &amp; privacy policies</a>
							</Checkbox>
						)}
					</FormItem>
					<FormItem>
						<Button
							style={{ cursor: 'pointer' }}
							type="primary"
							onClick={this.enterLoading}
							htmlType="submit"
							loading={formLoading}
							>
							Next step
						</Button>
					</FormItem>
				</Form>
			</div>
        );
	}
}

// STEPS
const Step = Steps.Step;
const WrappedNormalSignupForm = Form.create()(NormalSignupFormStep1);
const steps = [
	{
		title: 'Create Account',
		content: <WrappedNormalSignupForm />,
	},
	{
		title: 'Setup tracking',
		content: 'comp',
	},
	{
		title: 'Account Preferences',
		content: 'comp',
	},
];

class StepSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			current: 0,
		};
	}

	render() {
		const { current } = this.state;
		return (
			<div>
				<Steps size="small" current={current}>
					{steps.map((item) => (
						<Step key={item.title} title={item.title} />
					))}
				</Steps>
				<Divider />
				<div className="steps-content">{steps[current].content}</div>
				<div className="steps-action">
					{current === steps.length - 2 && (
						<Button type="primary" htmlType="submit">
							Validate Instagram
						</Button>
					)}
				</div>
			</div>
		);
	}
}
// end steps

class SignupPage extends React.Component {
	render() {
		return (
			<Row>
				<div className="logoDark" />
				<Divider />
				<Col>
					<StepSection />
				</Col>
				<Divider />
				<span>Other options:</span>
				<a href="/login"> Login</a>
			</Row>
		);
	}
}

export default SignupPage;
