import { message } from 'antd';
import moment from 'moment';
import React from 'react';
import AuthService from './components/authService';
import history from './history';

const utils = {
	auth: new AuthService(),
	user: {
		planOverride: {},
	},
	realID: '',
	uid: '',
	brand: null,
	asHost: '',
	params: {},

	adminMessage: null,

	isLocal: location.hostname === 'localhost',

	get currentUser() {
		return utils.auth.currentUser || {};
	},

	get initialUser() {
		return utils.auth.initialUser || {};
	},

	get isSubUser() {
		const p = utils.initialUser.profile;
		return !!p && p.isSub;
	},

	get isSuper() {
		return utils.realID !== utils.uid;
	},

	navRef: React.createRef(),

	isPuppy: !!navigator.userAgent.match(/HeadlessChrome/),
};

utils.reload = async () => {
	/* eslint no-restricted-globals: 0 */
	const loc = location.pathname;
	await utils.history.push('/');
	await utils.history.push(loc);
};

utils.home = (uid = null, type = null) => {
	const u = utils.currentUser,
		path = location.pathname.length > 1 ? location.pathname : '';
	if (!u) return utils.getPath('/login' + (path && '?p=' + path));

	const id = uid || u.id;

	switch (type || u.type) {
		case 'advertiser':
			return utils.getPath('/missionControl/' + id);
		case 'agency':
			return utils.getPath('/agency/' + id);
		case 'admin':
			return utils.getPath('/admin');
		default:
			return utils.getPath('/login' + (path && '?p=' + path));
	}
};

utils.queryParam = (p) => {
	const re = new RegExp(`(?:[?&]|^)` + p + `=([^&]+)`),
		m = location.search.match(re);
	console.log(location.search)
	return m && m.length === 2 ? m[1] : '';
};

utils.showErr = (err) => {
	if (!err) return;
	if (err instanceof Error) err = err.message;
	if (typeof err.toString === 'function') err = err.toString();
	if (typeof err !== 'string') err = JSON.stringify(err);
	message.error(<div dangerouslySetInnerHTML={{ __html: err }} />, err.indexOf('://') > -1 ? 45 : 10);
	if (err.toLowerCase().includes('unauthorized')) history.push('/login');
	return false;
};

utils.setLoading = (comp, key, v) => comp.setState({ ...(comp.state || {}), [key]: v });

utils.fmtTS = (ts, noTime = false) =>
	moment
		.unix(ts)
		.local()
		.format(`YYYY-MM-DD${noTime ? '' : ' HH:mm'}`);

utils.fmtTSFlipped = (ts) =>
	moment
		.unix(ts)
		.local()
		.format('MM-DD-YYYY HH:mm');

utils.expiresTS = (diff) => {
	var v = Math.floor(diff / 1000);
	if (v > 3600) return (v / 3600).toFixed(2) + 'h';
	if (v < 60) return v.toFixed(2) + 's';
	return (v / 60).toFixed(2) + 'm';
};

utils.splitAndTrim = (s, re = /\s+/) => (s || '').split(re).map((v) => v.trim()).filter(Boolean);
utils.fmtNum = (v, n = 2, def = null) => v == null ? def : v.toFixed(n).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace(/\.[0]+$/, '');

utils.forEach = (data, fn) => {
	if (!data || typeof data !== 'object') return;

	if (typeof data.forEach === 'function') return data.forEach(fn);

	for (const key of Object.keys(data)) {
		if (fn(data[key], key) === false) return;
	}
};

utils.map = (data, fn) => {
	if (typeof data === 'string') {
		try {
			data = JSON.parse(data); // wtf man..
		} catch (err) {
			return data;
		}
	}
	if (!data || typeof data !== 'object') return data;
	if (typeof data.map === 'function') return data.map(fn);
	return Object.keys(data).map((k) => fn(data[k], k));
}

utils.emptyObj = (o) => Object.keys(o).forEach((k) => delete o[k]);

utils.objSetter = (comp, key, defaultOn = {}) => {
	const parts = key.split('.'),
		topKey = parts.shift(),
		top = comp.state[topKey] || {},
		updateState = (state) => {
			state[topKey] = top;
			return state;
		};

	let o = top;
	parts.forEach((k) => {
		o[k] = o[k] || {};
		o = o[k];
	});

	const wrapped = {
		set(key, val, processFn) {
			if (typeof val === 'object' && typeof val.preventDefault === 'function') {
				val.preventDefault();
				val = val.target.value;
			}

			if (key === 'status' && val === false) utils.emptyObj(o);
			if (key === 'status' && val === true) utils.forEach(defaultOn, (v, k) => (o[k] = v));

			o[key] = processFn ? processFn(val, o) : val;
			comp.setState(updateState);
		},

		get(key, def = null) {
			return key in o ? o[key] : def;
		},

		setCB(key, processFn = null) {
			return (v) => wrapped.set(key, v, processFn);
		},
	};

	return wrapped;
};


utils.fmtPhone = (s) => {
	var s2 = (""+s).replace(/\D/g, '');
	var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
	return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
};

utils.isProd = window['location'].pathname.includes('/v2');

utils.getPath = (ep) => {
	if (!utils.isProd) return ep;
	if (ep.startsWith('/v2')) return ep;
	return '/v2' + ep;
};

utils.goto = async (ep) => {
	await utils.history.push({ pathname: '/' });
	await utils.history.replace(utils.getPath(ep));
};

utils.addScript = (src, onLoad = null) => {
	const scr = document.createElement('script');
	scr.src = src;
	scr.onload = onLoad;
	scr.async = scr.defer = true;
	document.getElementsByTagName('head').item(0).appendChild(scr);
};

utils.dateRangeLimiter = (days = 122) => {
	const max = moment().subtract(days, 'days'),
		today = moment();
	return (d) => !d || d.isBefore(max) || d.isAfter(today);
};

utils.sorter = (fld = null, ascended = true) => {
	const ffn = (v) => {
		try {
			if (!fld) return v;
			if (typeof fld === 'function') return fld(v);
			return v ? v[fld] : null;
		} catch (e) {
			return null;
		}
	};

	return (a, b) => {
		let av = ffn(ascended ? a : b),
			bv = ffn(ascended ? b : a);

		if (typeof av === 'string' || typeof av === 'string') {
			av = (av||'').toLocaleLowerCase();
			bv = (bv||'').toLocaleLowerCase();
			return av.localeCompare(bv);
		}

		if (av < bv) return -1;
		if (av > bv) return 1;
		return 0
	};
}

window['U'] = utils;
export default utils;
