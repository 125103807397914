export const ViewPerms = 1,
	PostPerms = 2,
	PutPerms = 4,
	DeletePerms = 8,
	NoPerms = 0,
	AllPerms = ViewPerms | PostPerms | PutPerms | DeletePerms;

export const DefaultViewOnly = () => ({
	ads: ViewPerms,
	campaigns: ViewPerms,
	segments: ViewPerms,
	spend: ViewPerms,
	ctr: ViewPerms,
	cvr: ViewPerms,
	cpc: ViewPerms,
	cpa: ViewPerms,
	storeVisits: ViewPerms,
	pixelConv: ViewPerms,
	domainStats: ViewPerms,
});

export const DefaultFull = () => ({
	ads: AllPerms,
	campaigns: AllPerms,
	segments: AllPerms,
	spend: AllPerms,
	ctr: AllPerms,
	cvr: AllPerms,
	cpc: AllPerms,
	cpa: AllPerms,
	storeVisits: ViewPerms,
	pixelConv: AllPerms,
	domainStats: AllPerms,
});

export class Perms {
	constructor(perms = null) {
		if (perms && perms instanceof Perms) {
			this.perms = perms.perms;
		} else {
			this.perms = perms || DefaultFull();
		}
	}

	enable = (type, flag) => (this.perms[type] |= flag);
	disable = (type, flag) => (this.perms[type] &= ~flag);
	can = (type, flag) => (this.perms[type] & flag) !== 0;
	get = (type) => this.perms[type];
	set = (type, val) => (this.perms[type] = val);
	hasType = (type) => (type in this.perms);
	canView = (type) => this.can(type, ViewPerms);

	get value() {
		return this.perms;
	}
}
